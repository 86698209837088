import React from "react"
import Header from "../components/header.js"
import Seo from "../components/seo.js"

const ContactFormPage = () => (
  <>
    <Seo
      title="Marc Kreidler: Contact Form"
      description="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
      ogTitle="Marc Kreidler: Contact Form"
      ogDescription="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
      twTitle="Marc Kreidler: Contact Form"
      twDescription="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
      featuredImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
      ogImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
      twImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
    />
    <div className="single bg-offWhite">
      <div className="lg:flex">
        <Header />
        <div className="entry-content w-full pt-6 pb-16 lg:py-12">
          <div className="lg:my-12 px-6 lg:px-12 max-w-6xl mx-auto">
            <h1 className="text-center">Contact Me</h1>
            <p>
              Let me know how I can help you meet your organizational or
              business goals. <em>All fields are required</em>.
            </p>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="contact" />
              <label htmlFor="name" className="form-label mb-3">
                Name: <br />
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  required
                />
              </label>
              <label htmlFor="email" className="form-label mb-3">
                Email: <br />
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  required
                />
              </label>
              <label htmlFor="subject" className="form-label mb-3">
                Subject: <br />
                <input
                  className="form-control"
                  type="text"
                  name="subject"
                  required
                />
              </label>
              <label htmlFor="message" className="form-label mb-5">
                Message: <br />
                <textarea className="form-control" name="message" required />
              </label>
              <input
                className="rounded py-1.5 px-3 bg-purple-light text-white border border-transparent hover:bg-purple-medium hover:text-white mx-auto block text-xl mb-6"
                type="submit"
                name="Send"
              />
              <br />
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ContactFormPage
